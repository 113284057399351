.footer-container {
    background-color: #242424;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .social-media {
    max-width: 1000px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  
  .social-media-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
  }
  
  .social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
 
  }
  .footer-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    padding: 20px;
  }
  .social-icon-link {
    color: #fff;
    
  }

  .my-email a{
    text-decoration: none; 
  background-color: white; 
  color: black; 
  padding: 2px 5px; 
  border-radius: 5px;
  text-align: center;
  font-family: 'Courier New', Courier, monospace;
  font-weight: 700;
  }

  .website-rights {
    color: #fff;
    margin-bottom: 16px;
    padding-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .section-website{
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    
  }
  
  @media screen and (max-width: 820px) {
  
    .social-media-wrap {
      flex-direction: column;
      
    }
  }