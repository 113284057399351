.dropdown-menu {
    background: rgb(255, 255, 255);
    width: 200px;
    position: absolute;
    top: 80px;
    list-style: none;
    text-align: start;
  }
  
  .dropdown-menu li {
    background: #000000;
    cursor: pointer;
  }
  
  .dropdown-menu li:hover {
    background: #778ba0;
  }
  
  .dropdown-menu.clicked {
    display: none;
  }
  
  .dropdown-link {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: #fff;
    padding: 16px;
  }
  
  @media screen and (max-width: 960px) {

    .dropdown-menu {
      width: 100%;
      top: 80px;
    }
  
    .dropdown-link {
      padding: 12px; /* Adjust padding for mobile */
    }

 /*.fa-caret-down {
      display: none;
    }*/
}