.cards_cmb {
    padding: 7rem 6.75rem 2.5rem;
    background-color: rgb(243, 238, 232);
    align-items: center;
  }
  
  .cards_cmb h1 {
    text-align: center;
    color: rgb(0, 0, 0);
    padding-top: 20px;
  }
  
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 100%;
    padding-top: 50px;
    margin: 0 auto;
  } 
  .cards__wrapper_cmb {
    position: relative;
    margin: 30px 0 45px;
  }
  
  .cards__items_cmb {
    margin-bottom: 24px;
  }
  
  .cards__item_cmb {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
  }

  
  .cards__item__link_cmb {
    display: flex;
    flex-flow: column;
    width: 100%;
   /* box-shadow: 0 6px 20px rgba(255, 244, 244, 0.17);*/
    overflow: hidden;
    text-decoration: none;
  }
  
 .cards__item__pic-wrap_cmb {
    position: relative;
    width: 100%;
    padding-top: 85%;
    overflow: hidden;
  }
  
  /*.fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }*/

  .cards__item__img_cmb {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 10px;
    
  }
  
  .cards__item__img_cmb:hover {
    -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  }
  
 .cards__item__info_cmb {
    padding: 40px 80px 10px;
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .cards__item__text_cmb {
    color: rgb(0, 0, 0);
    font-size: 18px;
    line-height: 24px;
    line-break: auto;
    text-align: left;
    font-family: 'Courier New', Courier, monospace;

  }
  .cards__items_cmb{
    height: 200%;
  }
.cards__items_cmb_1 .cards__item__img_cmb{
    height: 100%;
  
}

.cards__items_cmb_1 .cards__item__pic-wrap_cmb{
position: relative;
padding-top: 35%;

}
.cards__items_cmb_1 .cards__item__link_cmb{
    height: 5%;
    }

  .cards__item__label_cmb {
    color: white;
    font-size: 18px;
    line-height: 24px;
   
  }
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items_cmb {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item_cmb {
      margin-bottom: 2rem;
    }
  }
  @media only screen and (max-width: 700px) {
    .cards__item_cmb{
      width: 270px;
      display: flex;
      align-items: center;
      
    }
    .cards__container{
      display: flex;
      align-items: center;
    }
  }