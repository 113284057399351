.cards {
    padding: 7rem 6.75rem 2.5rem;
    background: rgb(250, 250, 250);
  }
  
  h1 {
    text-align: center;
    color: rgb(0, 0, 0);
    padding-top: 20px;
    padding-bottom: 40px;
  }
  
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: self-start;
    max-width: 1120px;
    width: 90%;
    padding-top: 50px;
    margin: 0 auto;
  }
  
  .cards__wrapper {
    position: relative;
    margin: 30px 0 45px;
  }
  
  .cards__items {
    margin-bottom: 24px;
  }
  
  .cards__item {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
    border: 1px solid #000; /* Add border around the card */
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(150, 176, 223, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(86, 190, 211, 0.017));
    filter: drop-shadow(0 6px 20px rgba(72, 133, 247, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 20px 30px 30px;
    position: relative;
    display: flex;
  }
  
  .cards__item__text {
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    line-break: auto;
    font-family: 'Courier New', Courier, monospace;

  }

  .cards__item__label {
    color: rgb(0, 0, 0);
    font-size: 18px;
    line-height: 24px;
   
  }

  .location a p{
    font-style: none;
    color: black;
  }
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
  }
  @media only screen and (max-width: 700px) {
    .cards__item{
      width: 270px;
      display: flex;
      align-items: center;
      
    }
    .cards__container{
      display: flex;
      align-items: center;
    }
  }