.escapades{
    display: grid;
    justify-content: space-between;
    align-items: center;
    column-gap: 30px;
    transition-delay: 1s;
  
}
.content .escapades{
    grid-template-columns: 60% 40%;
}
.content .wrapper{
    width: 100%;
    padding-bottom: 1.25rem;
}
.content2 .wrapper{
    width: 100%;
    padding-bottom: 1.25rem;
}
 .escapades-text p{
    color: rgb(0, 0, 0);
    font-size: large;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    line-height: 25px;
    padding-top: 20px;
 }
 .head-1 h1{
  color: rgb(0, 0, 0);
  padding-bottom: 40px;
  font-family: 'IBM Plex Mono', monospace;
  text-align: left;
 }
.escapades img{
    padding: 50px;
    
}
  
 .container {
    /*background: url('/public/image-4.png') center center/cover no-repeat; */
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);*/
    object-fit: contain;
    background-color: rgb(243, 238, 232);

  }
.container-img{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    
}


@media screen and (max-width: 960px) {
    .container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
    .container{
      height: 100%;

  }
}
  
  @media screen and (max-width: 768px) {
    .container > h1 {
      font-size: 50px;
      margin-top: -150px;
    }
 
 
}


@media only screen and (max-width: 600px)
{
    .escapades {
        display: flex;
        flex-wrap: wrap;
        
    }
    .container{
      height: 100%;
    }
    .content{
      padding: 3.125rem 2.75rem 3rem;
  }

    .content .escapades{
        flex-wrap: wrap-reverse;
        

    }
   .escapades img.wrapper{
    max-width: 100%;
   
   }
    
}