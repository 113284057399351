
.navbar {
  background: linear-gradient(90deg, rgb(157, 227, 240) 0%, rgb(88, 203, 235) 100%);
  /*background-color: rgb(131, 209, 240);*/
  height: 80px;
 
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  border-top: 1px solid #000; /* Border at the top */
  border-bottom: 1px solid #000; /* Border at the bottom */
  border-top-left-radius: 10px; /* Curved top-left corner */
  border-top-right-radius: 10px; /* Curved top-right corner */
 
}
.navbar::after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #000;
  position: absolute;
  bottom: 0;
}

.navbar-container {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 80px;
  max-width: 1500px;
  background-color: transparent;
}

.navbar-logo {
  color: #050505;
  justify-self: left;
  margin-left: 10px;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.75rem;
  display: flex;
  align-items: center;
  font-family:  Sans, sans-serif;
  font-weight: 800;
}


.nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 5px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 1rem;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #000000;
  display: flex;
  align-items: center;
  text-align: right;
  text-decoration: none;
  padding: 0.5rem 0.5rem;
  height: 100%;
  font-family: Monaco, monospace;
}
.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-links:hover {
  border-bottom: 4px solid rgb(53, 64, 113);
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #000000;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;

}
.fa-caret-down{
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

@media screen and (max-width: 960px){
  

  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    /*height: 90vh%;*/
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    padding-bottom: 15px;
  }

  .nav-menu.active {
    background:rgb(215, 212, 209);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    
  }

 .fa-times {
    color: #000000;
    font-size: 1.8rem;
  }
 .fa-caret-down{
    margin-left: 0.5rem;
    font-size: 1.6rem;
   
  }
  
} 