body {
  overflow-x: hidden;
}



.content {
  height: auto;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: flex-start;
  padding: 2.125rem 5%; /* Adjust padding for responsiveness */
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.about-me {
  display: grid;
  justify-content: space-between;
  align-items: center;
  column-gap: 30px;
  row-gap: 20px; /* Add spacing between rows for smaller screens */
  transition-delay: 1s;
  grid-template-columns: 1fr; /* Default to one column */
}

.content .about-me {
  grid-template-columns: 60% 35%; /* Adjust proportions for better alignment */
}

.content .wrapper {
  width: 100%;
  padding: 2rem 1rem; /* Reduce padding for smaller devices */
}

.about-me-text p {
  color: rgb(0, 0, 0);
  font-weight: 450;
  font-size: 1rem; /* Use relative font sizes */
  font-family: sans-serif;
  line-height: 1.6; /* Improve readability */
  margin: 0; /* Remove default margin to avoid overflow */
  padding-top: 20px; /* Reduce padding for smaller devices */
  word-wrap: break-word; /* Prevent text from overflowing */
}
   
    
    
 
 .about-me_container h1{
  color: rgb(0, 0, 0);
  padding-bottom: 30px;
 }

  
 .about-me_container {
    /*background: url('/public/png-wall.png') center center/cover no-repeat; */
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);*/
    background-color: rgb(243, 238, 232);
    object-fit: contain;
  
  }
 
  .location {
    text-align: center;
    padding-bottom: 5px;
   
  }
  
  .location p {
    background-color: #8cdceb;
    padding: 5px;
    display: inline-block;
    border-radius: 10px;
    margin-bottom: 5px;
    border: 2px solid #000; /* Increase border width to make it larger */
    font-weight: bold;
  }

p a {
  text-decoration: none; 
  background-color: white; 
  color: black; 
  padding: 2px 5px; 
  border-radius: 5px;
  font-weight: 600;
}
  .education_container {
    /*background: url('/public/image-3.png') center center/cover no-repeat; */
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    background-color: black;
  }

  .education_container h1{
    color: rgb(243, 238, 232);
    padding-bottom: 30px;
    
 }

 ul.education-text {
  border: 3px solid #ccc; /* Adds a light gray border */
  padding: 25px; /* Adds space inside the border */
  margin: 15px 0; /* Adds space between the sections */
  border-radius: 8px; /* Optional: Rounds the corners of the border */
  background-color: #000000; /* Optional: Adds a subtle background color */
}
.education {
  display: grid;
  grid-template-rows: auto; /* Ensures rows adjust based on content size */
  gap: 20px; /* Adds spacing between rows */
}

/* Optional: Center alignment for better appearance */
.content .education {
  justify-items: center; /* Centers items horizontally */
  align-items: start; /* Aligns items to the top of each row */
}

   .education-text{
      color: rgb(255, 255, 255);
      font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
      line-height: 30px;
   }
   .education-text h1{
    text-align: left;
    line-height: 40px;
    font-weight: bolder;
    text-decoration: underline;
   }
   .education-text p{
    padding-top: 40px;
    text-align: left;
    font-style: italic;
    font-weight: lighter;
    
   }
   .education_container h1{
    color: rgb(255, 255, 255);
    padding-bottom: 40px;
    padding-top: 10px;
 }
 .education-text li{
margin-left: 20px;
 }
 .content {
  padding: 10px;
}
 h3 a {
  text-decoration: none; 
  background-color: rgb(121, 242, 246); 
  color: black; 
  padding: 2px 5px; 
  border-radius: 5px;
}


  
 
  .awards_container {
    /*background: url('/public/image-3.png') center center/cover no-repeat; */
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    /*box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);*/
    object-fit: contain;
    background-color: rgb(243, 238, 232);
  }
  
  .awards_container h1{
    color: rgb(0, 0, 0);
    padding-bottom: 20px;
    text-align: left;
    padding-left: 6.75rem;
    
 }

  .awards{
    display: grid;
    justify-content: space-between;
    align-items: start;
    column-gap: 30px;
  }
  .content .awards{
    grid-template-columns: 30% 35% 25%;
  }
   .awards-text{
      color: rgb(0, 0, 0);
      font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
      line-height: 30px;
   }
   .awards-text p{
    padding-bottom: 10px;
   }

   .content {
    height: auto;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    align-items: flex-start;
    padding: 2.125rem 6.75rem 2.5rem;
 }

.music {
    display: grid;
    justify-content: space-between;
    align-items: center;
    column-gap: 30px;
  
}
.content .music{
    grid-template-columns: 50% 40%;
}

 .music-text p{
    color: white;
    font-size: large;
    font-family: Monaco, monospace;
    line-height: 25px;
    font-weight: 450;
    padding-top: 25px;
 }
 .music_container h1{
  color: white;
  padding-bottom: 40px;
 }
.music li {
  color: rgb(255, 255, 255);
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  line-height: 20px;
  padding-top: 5px;
  text-align: left;
  padding-left: 10px;
  font-weight: 800;
}
  
 .music_container {
    background: url('/public/image-6.png') center center/cover no-repeat; 
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    background-color: black;
  }
.location p a{
  padding: 2px; 
  display: inline-block; 
  border-radius: 5px; 
  margin-bottom: 5px; 
  font-weight: 800;
  background-color:  #8cdceb;;

}

   @media screen and (max-width: 960px) {
    .about-me_container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
    .education_container > h1 {
      font-size: 70px;
      margin-top: 100px;
    }
    .about-me-text p{
      font-weight: 400;
    }
    .about-me_container{
      height: 100%;
      width: 100%;
    }
    .education-text > h1{
      padding-top: 30px;
    }
    .education{
      height: 100%;
    }
    .education_container{
      height: 100%;
    }
    .content .education{
      grid-template-columns: 99%;
    }
    .awards_container > h1 {
      font-size: 40px;
      margin-top: 10px;
    }
    .awards{
      height: 100%;
    }
    .awards_container{
      height: 100%;
    }
    .content .awards{
      grid-template-columns: 99%;
    }
    .music_container{
      height: 100%;
    }
  }
  
  @media screen and (max-width: 768px) {
    .about-me_container > h1 {
      font-size: 50px;
      margin-top: -150px;
    }
    .education_container > h1 {
      font-size: 50px;
      margin-top: 70px;
    }
    .awards_container > h1 {
      font-size: 50px;
      margin-top: 10px;
    }

    .about-me-text p{
      font-weight: 400;
    }
    .about-me_container{
      height: 100%;
      width: 100%;
    }
    .content {
      padding: 1rem; /* Reduce padding on smaller screens */
  }
  .about-me {
      grid-template-columns: 1fr; /* Stack items vertically */
  }
  .about-me-text p {
      font-size: 0.9rem; /* Adjust text size for smaller screens */
  }
}

 


@media only screen and (max-width: 600px)
{
    .about-me {
        display: flex;
        flex-wrap: wrap;
        
    }
    .about-me_container{
      height: 100%;
      width: 100%;
    }
    .content{
      padding: 3.125rem 2.75rem 3rem;
  }

    .music{
      display: flex;
      flex-wrap: wrap;
    }
    .music_container{
      height: 100%;
    }
    .content .about-me{
        flex-wrap: wrap-reverse;
        

    }
   .about-me img.wrapper{
    max-width: 100%;
   
   }
    .content .education-text{
      width: 100%;
  }
  .content .music{
    width: 100%;
    flex-wrap: wrap-reverse;
}
.about-me-text p{
  font-weight: 400;
}
}
